import React from 'react'

const Linkdin = (props:{size:number}) => {
  return (
    <svg
      width={props.size?props.size:"35"}
      height={props.size?props.size:"35"}
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M32.4178 0.00013254H2.58195C1.90499 -0.0067569 1.25295 0.255184 0.768901 0.72848C0.284849 1.20178 0.00832284 1.84777 0 2.5247V32.481C0.00982799 33.157 0.287015 33.8015 0.770904 34.2736C1.25479 34.7456 1.90597 35.0068 2.58195 34.9999H32.4178C33.0948 35.0052 33.7464 34.7424 34.2302 34.2688C34.714 33.7952 34.9907 33.1494 34.9997 32.4724V2.5161C34.9877 1.84114 34.7097 1.19823 34.2262 0.727111C33.7427 0.255988 33.0928 -0.00526707 32.4178 0.00013254Z"
        fill="#0076B2"
      />
      <path
        d="M5.18118 13.1165H10.3766V29.8332H5.18118V13.1165ZM7.78034 4.79688C8.37623 4.79688 8.95874 4.97361 9.45417 5.30473C9.94959 5.63585 10.3357 6.10648 10.5636 6.65706C10.7915 7.20765 10.851 7.81347 10.7345 8.39787C10.6181 8.98227 10.3309 9.519 9.90932 9.94016C9.48776 10.3613 8.95076 10.648 8.36624 10.7639C7.78173 10.8798 7.17597 10.8197 6.6256 10.5913C6.07523 10.3629 5.60498 9.97633 5.27433 9.48059C4.94368 8.98485 4.7675 8.40217 4.76807 7.80628C4.76883 7.00787 5.08653 6.24242 5.65136 5.67813C6.21618 5.11384 6.98193 4.79687 7.78034 4.79688ZM13.6356 13.1165H18.6159V15.4115H18.6848C19.379 14.0976 21.0716 12.712 23.5991 12.712C28.8605 12.7005 29.8359 16.1632 29.8359 20.6529V29.8332H24.6405V21.7C24.6405 19.7636 24.606 17.2706 21.9409 17.2706C19.2757 17.2706 18.8225 19.382 18.8225 21.5738V29.8332H13.6356V13.1165Z"
        fill="white"
      />
    </svg>
  )
}

export default Linkdin
