export const JOB_NOT_AVAILABLE_HEADING = "We currently don't have any active job openings."
export const EEO_STATEMENT1 = "We are committed to providing equal employment opportunities to all applicants and employees. We are dedicated to fostering an inclusive environment where everyone feels valued and respected. We do not discriminate on the basis of race, color, religion, sex (including pregnancy, gender identity, and sexual orientation), national origin, age, disability, genetic information, veteran status, or any other legally protected characteristic."
export const EEO_STATEMENT2 = " All employment decisions are based on qualifications, merit, and business needs. We believe in creating a diverse workforce that reflects the communities we serve and promotes innovation and creativity."

//form submission warning msg
export const FORM_SUBMISSION_WARNING_MSG = "Please provide a response for this field."
export const URL_FORMATE = "Invalid url format."
export const EMAIL_FORMATE = "Invalid email format."

export const TACITBASE = "Tacitbase"

//
export const PAGE_NOT_FOUND = "We couldn't find what you were looking for."

export const PAGE_NOT_FOUND_TITLE = `Page not found | ${TACITBASE}`;

