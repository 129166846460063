import React from 'react'
import { Button, Tooltip } from 'antd'
import { ButtonProps } from 'antd/lib/button/button'


//button with icon and label
interface LabeledIconButtonProps extends ButtonProps {
    type?: 'default' | 'primary' | 'link' | "text", // Specify available button types
    onClick?: (event: any) => void, // Optional click event handler,
    label: string,
    htmlType?: "button" | "submit" | "reset" | undefined,
    icon?: any // Pass an icon component
}

export const LabeledIconButton: React.FC<LabeledIconButtonProps> = ({
    type = 'default',
    onClick,
    label,
    icon,
    htmlType = "submit",
    ...ButtonProps
}) => (
    <Tooltip>
        <Button

            htmlType={htmlType}
            {...ButtonProps}
            type={type} icon={icon} onClick={onClick}>
            {label}
        </Button>
    </Tooltip>
)


//icon button
interface IconButtonProps extends ButtonProps {
    onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
    icon: React.ReactNode
    type?: "default" | "primary" | "link" | "text"
    size?: "small" | "middle" | "large"
    tooltipTitle?: string
}

export const IconButton: React.FC<IconButtonProps> = ({
    onClick,
    icon,
    type = 'default',
    size = 'small',
    tooltipTitle,
    shape
}) => (
    <Tooltip title={tooltipTitle}>
        <Button type={type} size={size} shape={shape} icon={icon} onClick={onClick} />
    </Tooltip>
)
