import React from "react"

const CareerPageBg = () => {
  return (
    <div className="svg-container">
      <svg
        viewBox="0 0 1965 895"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="xMidYMid slice"
      >
        <rect width="100%" height="867" fill="url(#paint0_linear_5586_30555)" />
        <path
          d="M895.5 283.393C1282.3 589.616 1532.76 266.006 1717 629.893C1806 805.667 1963 866.968 1963 866.968H0V248C0 248 401 -108.097 895.5 283.393Z"
          fill="#802DE0"
        />
        <path
          d="M924.5 473.092C1311.29 779.318 1963 362.095 1963 362.095V866.596H0V248.51C0 248.51 329 1.63257 924.5 473.092Z"
          fill="url(#paint1_linear_5586_30555)"
        />
        <path
          d="M0 247.931C0 247.931 411.5 -34.8947 900.5 513.37C1025.18 653.164 1420 689.313 1463 867H0V247.931Z"
          fill="url(#paint2_linear_5586_30555)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_5586_30555"
            x1="143.5"
            y1="737"
            x2="1852.5"
            y2="133.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFA9DC" />
            <stop offset="0.470614" stopColor="#FCD6D5" />
            <stop offset="0.754014" stopColor="#BA5ADE" />
            <stop offset="1" stopColor="#471497" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_5586_30555"
            x1="1815"
            y1="406.001"
            x2="981.001"
            y2="742.501"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#7037E1" />
            <stop offset="1" stopColor="#0124AA" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_5586_30555"
            x1="1048.5"
            y1="443.482"
            x2="118.567"
            y2="920.737"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#31CBEF" />
            <stop offset="0.390387" stopColor="#A7E3FF" />
            <stop offset="1" stopColor="#0B7FB5" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  )
}

export default CareerPageBg
