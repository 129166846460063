import { Divider, Skeleton } from 'antd';
import React from 'react';

const JobCardSkeleton = () => {
    return (
        <div 
        className="flex flex-col justify-start"
        style={{ margin: "0 auto" }}
        >
          {[...Array(10)].map((_, index) => (
            <React.Fragment key={index}>
              <Divider style={{margin:2}} />
                <div className=" lg:flex w-full  lg:justify-between max-w-full sm:space-x-0 ">
                  <div className="flex-col space-y-2 flex lg:justify-end lg:flex flex-wrap text-left max-w-80 my-2">
                    <div className=" lg:w-72 w-36"> {/* Adjust these widths as needed */}
                      <Skeleton active title={false} paragraph={{ rows: 1, width: '100%' }} />
                    </div>
                    <div className="w-24 lg:w-36"> {/* Adjust these widths as needed */}
                      <Skeleton active title={false} paragraph={{ rows: 1, width: '100%' }} />
                    </div>
                  </div>
                  <div className="space-x-2 flex w-full flex-row justify-between lg:justify-end items-end sm:text-left lg:items-center sm:pt-0">
                    <div className="w-16 sm:w-24"> {/* Adjust these widths as needed */}
                      <Skeleton active title={false} paragraph={{ rows: 1, width: '100%' }} />
                    </div>
                    <div className="flex space-x-2 items-center">
                      <Skeleton.Button active className="w-16 rounded" />
                    </div>
                  </div>
                </div>
            </React.Fragment>
          ))}
        </div>
    );
};

export default JobCardSkeleton;