import React from "react"
// import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter"
import CustomFormField from "../formBuilder/CustomFormField"
import Image from "./Image"
import { ExtendedTableColumn } from "../../../types/type"
const FormHosting = (props: { componyLogo: string, isLoading: boolean, formData: ExtendedTableColumn[], setValue: (value: any) => void, onSubmit: () => void }) => {


  return (
    <>
      {" "}
      <div className={`animate-popover_fadeIn flex w-full  justify-center items-center`}>
        <div className="w-full flex-col shadow bg-white  lg:border lg:border-tacitbase-tertiary1 lg:border-opacity-10 py-4 rounded-lg">
          <div className="flex justify-start  px-8  items-center text-color-grey " onKeyDown={() => { }} tabIndex={0} role="button" >
            {props.componyLogo.trim().length > 0 && <Image src={props.componyLogo} size={"24"} />}
          </div>

          {props.formData.map(
            (column: ExtendedTableColumn) =>
              column && (
                <div className={` px-6 py-2 relative`} key={column.view}>
                  <CustomFormField
                    showPreview={true}
                    column={column}
                    defaultValue={""}
                    setValue={props.setValue}
                    onClick={props.onSubmit}
                    isLoading={props.isLoading}
                  />
                </div>
              )
          )}
        </div>
      </div>

    </>
  )
}
export default FormHosting
