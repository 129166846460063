import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import FormPreview from './pages/formBuilder/FormPreview'
import '../src/styles/ats.css'
import 'react-phone-input-2/lib/style.css'
import PageNotFound from './pages/pageNotFound/PageNotFound'
import CompanyInfo from './pages/careerpage/CompanyInfo'
import JobDescription from './pages/careerpage/JobDescription'

const Ats = () => {
  return (
    <Router>
      <Routes>

        {/* new routes */}

        <Route path="/:company_name/:short_link_id" element={<JobDescription />} />
        <Route path="/:company_name" element={<CompanyInfo />} />
        <Route path="/:company_name/apply/:form_id" element={<FormPreview />} />
        <Route path="*" element={<PageNotFound />} />

      </Routes>
    </Router>
  );
};

export default Ats;
