/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import parse from "html-react-parser";
import { Link, useParams,useSearchParams } from "react-router-dom";
import { getJD } from "../../services/api"
import { Button, Divider, Skeleton } from "antd";
import { Typography, Tag } from "antd"
import { Jobs } from "../../../types/type"
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter"
import { Helmet } from "react-helmet"
import { renderCreatedAt } from "../../utils/renderCreatedAt";
import { AxiosError } from "axios"
import CareerPage from "./CareerPage"
import PageNotFound from "../pageNotFound/PageNotFound"
import { formatSalary } from "../../utils/salaryFormat";
const tacitbaseFavicon = require("../../images/favicon.ico")
const { Paragraph, Text } = Typography;

interface Params extends Record<string, string> {
  org_id: string;
  jd_id: string;
}
export const JDDefault: any = {
  id: "",
  title: "",
  description: "",
  company_name: "",
  location: "",
  attachment_url: "",
  number_of_open_positions: 0,
  skills_description: "",
  job_type: "",
  experience_level_from: 0,
  experience_level_to: 0,
  experience_level_in_string: "",
  salary_currency_type: "",
  salary_range_from: 0,
  salary_range_to: 0,
  salary_type: "",
  created_at: "",
  updated_at: "",
  creater_id: "",
  status: "",
  form_id: "",
  form_name: "",
  form_url: "",
  jd_public_url: "",
  board_id: "",
  country: "",
  country_code: "",
  state: "",
  city: "",
  department: "",
  org_info: {
    active: true,
    data: {
      company_size: "",
      description: "",
      facebook: "",
      insta: "",
      linkedin: "",
      location: "",
      mission_statement: "",
      twitter: "",
      type: true,
      website: "",
      youtube: "",
      logo: "",
    },
    id: "",
    name: "",
    state: "",
    tenantId: "",
    
  },
  workplace_type:"",
};





const JobDescription: React.FC = () => {
  const { short_link_id, company_name } = useParams<Params>()
  const params: any = { company_name, short_link_id };
  const [searchParams] = useSearchParams();
  const src_id = searchParams.get('tb_src');
  const medium_id = searchParams.get('utm_medium');




  const [jd, setJD] = useState<Jobs>(JDDefault);
  const [currencyType, setCurrencyType] = useState("");
  const [isPostAvailable, setIsPostAvailable] = useState("");
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isJDExist, setIsJDExist] = useState(true);

  // function to open job description form in new tab
  function openRoute() {
    let route = `${jd.form_url}`;

    // Append query parameters conditionally
    const queryParams = [];
    if (src_id) queryParams.push(`tb_src=${src_id}`);
    if (medium_id) queryParams.push(`utm_medium=${medium_id}`);
  
    if (queryParams.length > 0) {
      route += `?${queryParams.join('&')}`;
    }
  
    window.open(route, "_blank");
  }

  // function to show job description based on its id
  useEffect(() => {
    setLoading(true);
    getJD(params.short_link_id, params.company_name)
      .then((res) => {
        setIsPostAvailable("");
        setJD(res);
        setLoading(false);

      })
      .catch((reason: AxiosError) => {
        setLoading(false)
        if (reason.response?.status === 404) {
          setIsJDExist(false)
        }
        else if (reason.response?.status === 410) {

          setError(true)
          setIsPostAvailable("This job post has expired.")
          setLoading(false)
        }
      });
  }, []);

  // useEffect for converting response into first letter capitalized
  useEffect(() => {
    console.log({jd})
    if (jd.salary_currency_type === "inr") {
      setCurrencyType("INR");
    }
    if (jd.salary_currency_type === "usd") {
      setCurrencyType("USD");
    }
  }, [jd]);
  if (!isJDExist) return <PageNotFound />

  return (
    <>
      <CareerPage logo={jd.org_info.data.logo ? jd.org_info.data.logo : ""} title={jd.title}>
      {jd.id.length > 0 &&
        <Helmet>
          <title>{`${jd.title} | ${jd.company_name}`}</title>
          <link rel="icon" type="image/png" href={`${jd.org_info.data.logo} ? ${jd.org_info.data.logo} : ${tacitbaseFavicon}`} sizes="16x16" />
          <script type="application/ld+json">
            {JSON.stringify(jd.google_job)}
          </script>
        </Helmet>
      }
    <div className="flex flex-col  relative w-full bg-white">
      {error ? (
        <div>
          <Text
            className="font-bold text-black flex items-start"
            style={{ fontSize: "24px" }}
          >
            {isPostAvailable}
          </Text>
        </div>
      ) : (
        <div>
          {loading ? (
            <Skeleton className="mt-4 items-start" active />
          ) : (
            <div className="w-full">
              <div>
                <div className="flex items-start justify-between gap-4">
                  <div className="flex flex-col sm:items-start lg:items-center sm:flex-row gap-2">
                  {jd.title && (
                    <div className="flex w-full overflow-x-scroll gap-1">
                      <Link
                              to={`/${params.company_name}`}
                        className="font-bold whitespace-normal break-words text-black"
                        style={{ fontSize: "24px" }}
                      >
                        {capitalizeFirstLetter(jd?.title)}
                      </Link>
                    </div>
                  )}
                    {jd.status === "publish" &&( <div>
                    <Text type="secondary"
                          className=" overflow-ellipsis w-full truncate"
                          style={{ fontSize: "13px" }}
                        >
                         (Posted {renderCreatedAt(jd.created_at)})
                        </Text>
                    </div>)}
                    </div>
                  {jd.jd_public_url && (
                    <div className="flex sm:flex  items-center justify-center gap-2.5">
                      <div className = "hidden sm:flex">
                        <Button
                          type="primary"
                          className="font-normal rounded truncate focus:outline-none cursor-pointer flex items-center justify-center"
                          name="Apply"
                          onClick={openRoute}
                        >
                          Apply
                        </Button>
                      </div>
                      <div className="w-8 h-8 hidden sm:flex rounded-sm border border-gray-300  items-center justify-center">
                        <Paragraph
                                className="pt-4"
                          copyable={{ text: jd.jd_public_url }}
                          style={{ color: "##4b48a5" }}
                        />
                      </div>
                    </div>
                  )}
                </div>

                <div className="mt-2 flex justify-between  items-center">
                  <div className="flex gap-4 flex-col sm:flex-row justify-center">
                    {jd.company_name && (
                      <p
                        className="font-bold text-gray-500"
                        style={{ fontSize: "14px", minWidth:"100px" }}
                      >
                        {jd.company_name}
                      </p>
                    )}
                   {(jd.locations && jd?.locations.length > 0) && (
                    <div className="flex flex-wrap">
                      {jd?.locations?.map((loc, index) => {
                        const locationParts = [loc.city, loc.state, loc.country].filter(Boolean).join(', ');
                        return (
                          <div key={index}>
                            <Text type="secondary">
                              {locationParts}
                              {index !== (jd.locations && jd?.locations.length - 1) && (
                                <Divider style={{ marginInline: "6px",borderColor: 'rgba(5, 5, 5, 0.3)' }} type="vertical" />
                              )}
                            </Text>
                          </div>
                        );
                      })}
                    </div>
                    )}
                  </div>
                </div>
                <div className="flex  gap-2 mt-2 flex-wrap">
                  {jd.job_type && (
                    <Tag
                      bordered
                      style={{
                        border: "1px solid #D3ADF7",
                        borderRadius: "2px",
                        backgroundColor: "#F9F0FF",
                        height: "22px",
                        display: "flex",
                        justifyContent: "center",
                        color: "purple",
                        padding: "1px 8px",
                        gap: "3px",
                        alignItems: "center",
                      }}
                    >
                      {jd.job_type}
                    </Tag>
                  )}
                  {jd.workplace_type &&(
                    <Tag
                    bordered
                    style={{
                      border: "1px solid #D3ADF7",
                      borderRadius: "2px",
                      backgroundColor: "#F9F0FF",
                      height: "22px",
                      display: "flex",
                      justifyContent: "center",
                      color: "purple",
                      padding: "1px 8px",
                      gap: "3px",
                      alignItems: "center",
                    }}
                  >
                    {jd.workplace_type}
                  </Tag>
                  )}
                   {jd.department && (
                    <Tag
                      bordered
                      style={{
                        border: "1px solid #D3ADF7",
                        borderRadius: "2px",
                        backgroundColor: "#F9F0FF",
                        height: "22px",
                        display: "flex",
                        justifyContent: "center",
                        color: "purple",
                        padding: "1px 8px",
                        gap: "3px",
                        alignItems: "center",
                      }}
                    >
                      {jd.department}
                    </Tag>
                  )}
                  {jd.experience_level_to > 0 && (
                    <Tag
                      bordered
                      style={{
                        border: "1px solid #D3ADF7",
                        borderRadius: "2px",
                        backgroundColor: "#F9F0FF",
                        height: "22px",
                        display: "flex",
                        justifyContent: "center",
                        color: "purple",
                        padding: "1px 8px",
                        gap: "3px",
                        alignItems: "center",
                      }}
                    >
                      {jd.experience_level_from} - {jd.experience_level_to}{" "}
                      years
                    </Tag>
                  )}
                  {jd.salary_range_to > 0 && (
                    <Tag
                      bordered
                      style={{
                        border: "1px solid #D3ADF7",
                        borderRadius: "2px",
                        backgroundColor: "#F9F0FF",
                        height: "22px",
                        display: "flex",
                        justifyContent: "center",
                        color: "purple",
                        padding: "1px 8px",
                        gap: "3px",
                        alignItems: "center",
                      }}
                    >
                      {formatSalary(
                        jd.salary_range_from,
                            jd.salary_currency_type
                            )}{" - "}
                                
                            {formatSalary(
                              jd.salary_range_to,
                                  jd.salary_currency_type
                                )}
                            {" "}
                      {currencyType}
                    </Tag>
                  )}
                  {jd.number_of_open_positions > 0 && (
                    <Tag
                      bordered
                      style={{
                        border: "1px solid #D3ADF7",
                        borderRadius: "2px",
                        backgroundColor: "#F9F0FF",
                        height: "22px",
                        display: "flex",
                        justifyContent: "center",
                        color: "purple",
                        padding: "1px 8px",
                        gap: "3px",
                        alignItems: "center",
                      }}
                    >
                      {jd.number_of_open_positions > 1
                        ? `${jd.number_of_open_positions} openings`
                        : `${jd.number_of_open_positions} opening`}
                    </Tag>
                  )}
                </div>
              </div>
            </div>
          )}
          <div className="flex flex-col pb-8 space-y-2 mb-3">
            {loading ? (
              <Skeleton className="mt-12 items-start h-40" active />
            ) : (
              <div
                className="h3 break-words mt-10"
                style={{ fontSize: "15px" }}
              >
                {parse(jd.description)}
              </div>
            )}
          </div>
          {jd.jd_public_url && (
            <div className="flex gap-2.5">
              <div>
                <Button
                  type="primary"
                  className="font-normal rounded truncate focus:outline-none cursor-pointer flex items-center justify-center"
                  name="Apply"
                  onClick={openRoute}
                >
                  Apply
                </Button>
              </div>
              <div className="w-8 h-8 rounded-sm border border-gray-300 flex items-center justify-center">
                <Paragraph
                      className="pt-4"
                  copyable={{ text: jd.jd_public_url }}
                  style={{ color: "#4b48a5" }}
                />
              </div>
            </div>
          )}
        </div>
      )}
        </div>
      </CareerPage >

    </>
  )
};

export default JobDescription;
