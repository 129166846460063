export interface Theme {
    borderRadius: number,
    colorPrimary: string,
    colorPrimaryBorder: string,
    lineWidthFocus?: number,
    Button: {
        colorPrimary: string,
        algorithm?: boolean,
    },
    Layout: {
        headerHeight: number, headerBg: string,
        headerPadding: string
    },
    Select: {
        optionSelectedFontWeight: number | undefined,
    },
    typography: {
        fontFamily: string
    },
    upload: {
        actionsColor: string,
    }
}

export const defaultTheme: Theme = {
    borderRadius: 4,    // lineWidthFocus: 4,
    colorPrimary: '#7152F2', //tacitbase primary color
    colorPrimaryBorder: "#7152F2",
    Button: {
        colorPrimary: '#4B48A5',
        algorithm: false,
    },
    Select: {
        optionSelectedFontWeight: 0,
    },
    Layout: {
        headerHeight: 52, headerBg: "#0C1E40",
        headerPadding: "0 12px"
    },
    typography: {
        fontFamily: 'Inter'
    },
    upload: {
        actionsColor: "#ff99cc",
    }
}

