import React from "react";

const Facebook = (props:{size:number}) => {
  return (
    <svg
    width={props.size?props.size:"35"}
    height={props.size?props.size:"35"}
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M33.0689 0H1.93114C0.864599 0 0 0.864599 0 1.93114V33.0689C0 34.1354 0.864599 35 1.93114 35H33.0689C34.1354 35 35 34.1354 35 33.0689V1.93114C35 0.864599 34.1354 0 33.0689 0Z"
        fill="#3D5A98"
      />
      <path
        d="M24.1468 34.9979V21.4445H28.6952L29.3754 16.1627H24.1468V12.7914C24.1468 11.2624 24.5727 10.2185 26.7641 10.2185H29.5617V5.48675C28.207 5.34581 26.8456 5.27868 25.4835 5.28565C21.4557 5.28565 18.6817 7.74023 18.6817 12.2679V16.1627H14.1333V21.4445H18.6817V34.9979H24.1468Z"
        fill="white"
      />
    </svg>
  );
};

export default Facebook;
