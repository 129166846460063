import React, { useEffect, useState } from "react";
import {
  Typography,
  Divider,
  Pagination,
  Select,
  AutoComplete,
  Input,
  ConfigProvider,
  Button,
  Badge,
} from "antd";
import { useParams } from "react-router-dom";
import { Link as Link1 } from "react-router-dom";
import { ArrowRightOutlined } from "@ant-design/icons";

import {
  getAllDepartmentOfCreatedJobs,
  getAllJDByOrgName,
} from "../../services/api";
import { JOB_NOT_AVAILABLE_HEADING } from "../../constant";
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter";
import "../../styles/jdSelectCustom.css";

import { renderCreatedAt } from "../../utils/renderCreatedAt";
import { formatSalary } from "../../utils/salaryFormat";
import JobCardSkeleton from "./JobCardSkeleton";
import throwError from "../../services/throwError"
import { AxiosError } from "axios"
import { jobD,location } from "../../../types/type";

const { Search } = Input;

const { Text, Link } = Typography;
const { Option } = AutoComplete;

const JobCard = () => {
  const { company_name, short_link_id } = useParams();
  const params: any = { company_name, short_link_id };
  const [jobDescriptions, setJobDescriptions] = useState<jobD[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalJobs, setTotalJobs] = useState(0);
  const [jobsPerPage, setJobsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const pageSizeRef = React.useRef(10);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedJobType, setSelectedJobType] = useState("");
  const [selectedWorkType, setSelectedWorkType] = useState("");
  const [departments, setDepartments] = useState<string[]>([]);
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [jobTypes, setJobTypes] = useState<string[]>([]);
  const [workplaceTypes, setWorkplaceTypes] = useState<string[]>([]);
  const [selectedLocation, setSelectedLocation] = useState<any>(null);

  //function to fetch all jobs of an organization
  const fetchJobs = async (page: number, perPage: number, query: string) => {
    const queryParams = new URLSearchParams();
    const filterParams = new URLSearchParams();

    if (selectedLocation) {
      const { city, state, country } = selectedLocation;

      if (city) {
        filterParams.append("city", city);
      }
      if (state) {
        filterParams.append("state", state);
      }
      if (country) {
        filterParams.append("country", country);
      }
    }

    if (selectedJobType) filterParams.append("job_type", selectedJobType);
    if (selectedWorkType)
      filterParams.append("workplace_type", selectedWorkType);
    if (selectedDepartment)
      filterParams.append("department", selectedDepartment);

    // Append filter as a single key
    if (filterParams.toString()) {
      queryParams.append("filter", filterParams.toString());
    }

    // Append search query if it exists
    if (query) {
      queryParams.append("query", query);
    }

    // Generate the final query string
    const queryString = queryParams.toString();
    setLoading(true);

    getAllJDByOrgName(
      params.company_name,
      page,
      perPage,
      queryString ? queryString : ""
    ).then((response) => {
      short_link_id
        ? setJobDescriptions(response.items.slice(0, 2))
        : setJobDescriptions(response.items)
      setTimeout(() => {
        setLoading(false)
      }, 1000)
      setTotalJobs(response.total_count)
    }).catch((reason: AxiosError) => {
      if (reason.response?.status === 400) {
        throwError(reason)
      }
    })

  };

  //function to handle number of job post per page
  useEffect(() => {

    // callng get department api integration
    getAllDepartmentOfCreatedJobs(params.company_name).then((res) => {
      setFilteredLocations(res.location);
      setJobTypes(res.job_type);
      setWorkplaceTypes(res.workplace_type);
      setDepartments(res.department);
    });

  }, []);


  // calling fetchdata function when params change
  useEffect(() => {
    const loadJobs = async () => {
      // setLoading(true);
      await fetchJobs(currentPage, jobsPerPage, searchQuery);
      // setLoading(false);
    };
    loadJobs();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    company_name,
    currentPage,
    jobsPerPage,
    selectedJobType,
    selectedWorkType,
    selectedDepartment,
    selectedLocation,
  ]);

  // function to handle or updates job per page
  const handlePageChange = (newCurrent: number, newPageSize: number) => {
    const pageSizeChange = pageSizeRef.current !== newPageSize;
    if (pageSizeChange) {
      setCurrentPage(1);
    } else {
      setCurrentPage(newCurrent);
    }
    pageSizeRef.current = newPageSize;
    setJobsPerPage(newPageSize);
  };
  // Function to handle search input
  const handleSearch = (value: string) => {
    setSearchQuery(value);
  };

  // Function to handle job type select
  const handleJobTypeChange = (value: string) => {
    setSelectedJobType(value);
  };

  const handleDepartmentChange = (value: string) => {
    setSelectedDepartment(value);
  };

  // Function to handle work type select
  const handleWorkTypeChange = (value: string) => {
    setSelectedWorkType(value);
  };

  const [filteredLocations, setFilteredLocations] = useState<location[]>([
    {
      city: "",
      state: "",
      country: "",
    },
  ]);

  const handleSelect = (value: string, option: any) => {
    setSelectedLocation(option.obj);
  };

  const downIcon = () => {
    return (
      <span role="img" aria-label="down" className=" text-xs">
        <svg
          viewBox="64 64 896 896"
          focusable="false"
          width="1em"
          height="1em"
          fill="currentColor"
        >
          <path d="M884 256h-75c-5.1 0-9.9 2.5-12.9 6.6L512 654.2 227.9 262.6c-3-4.1-7.8-6.6-12.9-6.6h-75c-6.5 0-10.3 7.4-6.5 12.7l352.6 486.1c12.8 17.6 39 17.6 51.7 0l352.6-486.1c3.9-5.3.1-12.7-6.4-12.7z"></path>
        </svg>
      </span>
    );
  };

  return (
    <div className="px-2">
      {short_link_id ? (
        <div className="my-2 flex justify-between w-full">
          <Text className=" text-lg font-semibold">Jobs</Text>
          <Link1
            className="font-medium text-base flex space-x-2  "
            to={`/${params.company_name}`}
            style={{ color: "#1677ff" }}
          >
            <span className="font-medium text-sm">{capitalizeFirstLetter("view all jobs")}</span>
            <span>
              <ArrowRightOutlined />
            </span>
          </Link1>
        </div>
      ) : (
        <div className="mb-2 flex justify-start">
          <div className="font-medium text-base overflow-ellipsis w-80 truncate   flex space-x-2 items-center">
            <span>{capitalizeFirstLetter("All jobs")}</span>
          </div>
        </div>
      )}

      {!short_link_id && (
        <div className="flex flex-col space-y-4 flex-container">
          <div className="flex space-x-4 ">
            <Search
              allowClear
              placeholder="Search jobs"
              onChange={(e) => handleSearch(e.target.value)}
              onSearch={(value) => fetchJobs(currentPage, jobsPerPage, value)}
              enterButton
              className=" w-72"
            />
          </div>
        
              {/* All selects in one row for large screens */}
              <div className="hidden  lg:flex gap-2 flex-wrap">
                <ConfigProvider
                  theme={{
                    components: {
                      Select: {
                        selectorBg: selectedLocation ? "#F0EFFE" : "#ffffff",
                        colorBorder: selectedLocation
                          ? "#F0EFFE"
                          : "rgba(0, 0, 0, 0.25)",

                        clearBg: "white",
                      },
                    },
                  }}
                >
                  <div className="flex">
                    <div>
                      <Select
                        allowClear
                        className=" rounded"
                        onClear={() => {
                          // setFilteredLocations([]);
                          setSelectedLocation(null);
                        }}
                        style={{
                          width: 290,
                        }}
                        placeholder={<>Location</>}
                        onSelect={handleSelect}
                        filterOption={false}
                      >
                        {filteredLocations.map((location, index) => location && (
                          <Option
                            key={index}
                            value={`${
                              location.city ? location.city + ", " : ""
                            }${location.state ? location.state + ", " : ""}${
                              location.country ? location.country : ""
                            }`}
                            obj={location}
                          >
                            {`${location.city ? location.city + ", " : ""}${
                              location.state ? location.state + ", " : ""
                            }${location.country ? location.country : ""}`}
                          </Option>
                        ))}
                      </Select>
                    </div>
                    <div
                      className={`-ml-6 mt-2.5 mr-3 ${
                        selectedLocation ? "hidden" : "z-10"
                      }`}
                      // unselectable="on"
                      aria-hidden="true"
                      style={{
                        userSelect: "none",
                        color: "rgba(0, 0, 0, 0.25)",
                      }}
                    >
                      {downIcon()}
                    </div>
                  </div>
                </ConfigProvider>
                <ConfigProvider
                  theme={{
                    components: {
                      Select: {
                        selectorBg: selectedJobType ? "#F0EFFE" : "#ffffff",
                        colorBorder: selectedJobType
                          ? "#F0EFFE"
                          : "rgba(0, 0, 0, 0.25)",
                      },
                    },
                  }}
                >
                  <div className="flex">
                    <div>
                      <Select
                        allowClear
                        // showSearch
                        className=""
                        placeholder="Job type"
                        style={{ width: 170 }}
                        onChange={handleJobTypeChange}
                      >
                        {jobTypes.map((type: string) => type && (
                          <Option value={type} key={type}>{type}</Option>
                        ))}
                      </Select>
                    </div>
                    <div
                      className={`-ml-6 mt-2.5 mr-3 ${
                        selectedJobType ? "hidden" : "z-10"
                      }`}
                      // unselectable="on"
                      aria-hidden="true"
                      style={{
                        userSelect: "none",
                        color: "rgba(0, 0, 0, 0.25)",
                      }}
                    >
                      {downIcon()}
                    </div>
                  </div>
                </ConfigProvider>
                <ConfigProvider
                  theme={{
                    components: {
                      Select: {
                        selectorBg: selectedDepartment ? "#F0EFFE" : "#ffffff",
                        colorBorder: selectedDepartment
                          ? "#F0EFFE"
                          : "rgba(0, 0, 0, 0.25)",
                      },
                    },
                  }}
                >
                  <div className="flex">
                    {" "}
                    <div>
                      <Select
                        allowClear
                        placeholder="Department"
                        style={{ width: 170 }}
                        onChange={handleDepartmentChange}
                      >
                        {departments?.map((depart, index) => (
                          <Option key={index} value={depart}>
                            {depart}
                          </Option>
                        ))}
                      </Select>
                    </div>
                    <div
                      className={`-ml-6 mt-2.5 mr-3 ${
                        selectedDepartment ? "hidden" : "z-10"
                      }`}
                      // unselectable="on"
                      aria-hidden="true"
                      style={{
                        userSelect: "none",
                        color: "rgba(0, 0, 0, 0.25)",
                      }}
                    >
                      {downIcon()}
                    </div>
                  </div>
                </ConfigProvider>
                <ConfigProvider
                  theme={{
                    components: {
                      Select: {
                        selectorBg: selectedWorkType ? "#F0EFFE" : "#ffffff",
                        colorBorder: selectedWorkType
                          ? "#F0EFFE"
                          : "rgba(0, 0, 0, 0.25)",
                      },
                    },
                  }}
                >
                  {" "}
                  <div className="flex">
                    <div>
                      <Select
                        allowClear
                        placeholder="Workplace type"
                        style={{ width: 170 }}
                        className="text-tacitbase-secondary3"
                        onChange={handleWorkTypeChange}
                      >
                        {workplaceTypes.map((type) => type && (
                          <Option value={type} key={type}>{type}</Option>
                        ))}
                      </Select>
                    </div>
                    <div
                      className={`-ml-6 mt-2.5 mr-3 ${
                        selectedWorkType ? "hidden" : "z-10"
                      }`}
                      // unselectable="on"
                      aria-hidden="true"
                      style={{
                        userSelect: "none",
                        color: "rgba(0, 0, 0, 0.25)",
                      }}
                    >
                      {downIcon()}
                    </div>
                  </div>
                </ConfigProvider>
              </div>

              {/* Two selects per row for small screens */}
              <div className="flex flex-col space-y-2 lg:hidden">
                <div className="flex space-x-2">
                  <ConfigProvider
                    theme={{
                      components: {
                        Select: {
                          selectorBg: selectedLocation ? "#F0EFFE" : "#ffffff",
                          colorBorder: selectedLocation
                            ? "#F0EFFE"
                            : "rgba(0, 0, 0, 0.25)",
                          clearBg: "white",
                        },
                      },
                    }}
                  >
                    <div className="flex">
                      <div>
                        <Select
                          allowClear
                          className=" rounded"
                          onClear={() => {
                            // setFilteredLocations([]);
                            setSelectedLocation(null);
                          }}
                          style={{
                            width: 140,
                          }}
                          placeholder={<>Location</>}
                          onSelect={handleSelect}
                          filterOption={false}
                        >
                          {filteredLocations.map((location, index) => (
                            <Option
                              key={index}
                              value={`${
                                location.city ? location.city + ", " : ""
                              }${location.state ? location.state + ", " : ""}${
                                location.country ? location.country : ""
                              }`}
                              obj={location}
                            >
                              {`${location.city ? location.city + ", " : ""}${
                                location.state ? location.state + ", " : ""
                              }${location.country ? location.country : ""}`}
                            </Option>
                          ))}
                        </Select>
                      </div>
                      <div
                        className={`-ml-6 mt-2.5 mr-3 ${
                          selectedLocation ? "hidden" : "z-10"
                        }`}
                        // unselectable="on"
                        aria-hidden="true"
                        style={{
                          userSelect: "none",
                          color: "rgba(0, 0, 0, 0.25)",
                        }}
                      >
                        {downIcon()}
                      </div>
                    </div>
                  </ConfigProvider>
                  <ConfigProvider
                    theme={{
                      components: {
                        Select: {
                          selectorBg: selectedJobType ? "#F0EFFE" : "#ffffff",
                          colorBorder: selectedJobType
                            ? "#F0EFFE"
                            : "rgba(0, 0, 0, 0.25)",
                        },
                      },
                    }}
                  >
                    <div className="flex">
                      <div>
                        <Select
                          allowClear
                          // showSearch
                          className="text-tacitbase-secondary3"
                          placeholder="Job type"
                          style={{ width: 140 }}
                          onChange={handleJobTypeChange}
                        >
                          {jobTypes.map((type: string) => (
                            <Option value={type} key={type}>{type}</Option>
                          ))}
                        </Select>
                      </div>
                      <div
                        className={`-ml-6 mt-2.5 mr-3 ${
                          selectedJobType ? "hidden" : "z-10"
                        }`}
                        // unselectable="on"
                        aria-hidden="true"
                        style={{
                          userSelect: "none",
                          color: "rgba(0, 0, 0, 0.25)",
                        }}
                      >
                        {downIcon()}
                      </div>
                    </div>
                  </ConfigProvider>
                </div>
                <div className="flex space-x-2">
                  <ConfigProvider
                    theme={{
                      components: {
                        Select: {
                          selectorBg: selectedDepartment
                            ? "#F0EFFE"
                            : "#ffffff",
                          colorBorder: selectedDepartment
                            ? "#F0EFFE"
                            : "rgba(0, 0, 0, 0.25)",
                        },
                      },
                    }}
                  >
                    <div className="flex">
                      {" "}
                      <div>
                        <Select
                          allowClear
                          placeholder="Department"
                          style={{ width: 140 }}
                          onChange={handleDepartmentChange}
                        >
                          {departments?.map((depart, index) => (
                            <Option key={index} value={depart}>
                              {depart}
                            </Option>
                          ))}
                        </Select>
                      </div>
                      <div
                        className={`-ml-6 mt-2.5 mr-3 ${
                          selectedDepartment ? "hidden" : "z-10"
                        }`}
                        // unselectable="on"
                        aria-hidden="true"
                        style={{
                          userSelect: "none",
                          color: "rgba(0, 0, 0, 0.25)",
                        }}
                      >
                        {downIcon()}
                      </div>
                    </div>
                  </ConfigProvider>
                  <ConfigProvider
                    theme={{
                      components: {
                        Select: {
                          selectorBg: selectedWorkType ? "#F0EFFE" : "#ffffff",
                          colorBorder: selectedWorkType
                            ? "#F0EFFE"
                            : "rgba(0, 0, 0, 0.25)",
                        },
                      },
                    }}
                  >
                    {" "}
                    <div className="flex">
                      <div>
                        <Select
                          allowClear
                          placeholder="Workplace type"
                          style={{ width: 140 }}
                          className="text-tacitbase-secondary3"
                          onChange={handleWorkTypeChange}
                        >
                          {workplaceTypes.map((type) => (
                            <Option value={type} key={type}>{type}</Option>
                          ))}
                        </Select>
                      </div>
                      <div
                        className={`-ml-6 mt-2.5 mr-3 ${
                          selectedWorkType ? "hidden" : "z-10"
                        }`}
                        // unselectable="on"
                        aria-hidden="true"
                        style={{
                          userSelect: "none",
                          color: "rgba(0, 0, 0, 0.25)",
                        }}
                      >
                        {downIcon()}
                      </div>
                    </div>
                  </ConfigProvider>
                </div>
         
          </div>
          <div >{totalJobs} results</div>{" "}
        </div>
      )}
      {loading ? (
        <>
          <JobCardSkeleton />
        </>
      ) : (
        <>
          <div style={{ margin: "0 auto" }}>
            {jobDescriptions.length === 0 && (
              <div className="w-full mt-4 flex justify-start ">
                <Text className="text-base text-gray-500">
                  {JOB_NOT_AVAILABLE_HEADING}
                </Text>
              </div>
            )}
          </div>

          <div
            className="flex flex-col justify-start"
            style={{ margin: "0 auto" }}
          >
            {jobDescriptions
              .filter(
                (jobDescription) =>
                  jobDescription.short_link_id !== short_link_id
              )
                .map((jobDescription) => jobDescription && (
                <>
                  <Divider style={{ margin: 2 }} />
                  <div className=" lg:flex w-full  lg:justify-between max-w-full sm:space-x-0  ">
                    <Link href={jobDescription.jd_public_url}  target="_blank" className="flex lg:justify-end lg:flex flex-col flex-wrap text-left  w-full my-2 ">
                      <div
                        className="font-medium text-base overflow-ellipsis w-min truncate"
                      >
                        {capitalizeFirstLetter(jobDescription.title)}
                      </div>

                      <div className="items-center flex overflow-ellipsis truncate  max-w-80">
                        <Text type="secondary">
                          {capitalizeFirstLetter(jobDescription.workplace_type)}
                        </Text>
                        &nbsp;{" "}
                        {jobDescription?.locations?.length > 0 && (
                          <Text
                            // className="text-gray-500  truncate" 
                            type="secondary" 
                          >
                            <Badge /> {jobDescription.locations[0].city}
                          </Text>
                        )}
                        &nbsp;
                        {jobDescription.salary_range_from > 0 &&
                          jobDescription.salary_range_to > 0 && (
                            <div className="overflow-ellipsis truncate w-40">
                              <Text className=" overflow-ellipsis truncate " type="secondary">
                                {formatSalary(
                                  jobDescription.salary_range_from,
                                  jobDescription.salary_currency_type
                                )}{" - "}
                                
                                {formatSalary(
                                  jobDescription.salary_range_to,
                                  jobDescription.salary_currency_type
                                )}
                                ({jobDescription.salary_type})
                              </Text>
                            </div>
                          )}{" "}
                      </div>
                    </Link>
                    <div className="space-x-2 flex w-auto flex-row justify-between lg:justify-end items-end  sm:text-left lg:items-center  sm:pt-0">
                      <div className="items-center flex ">
                        <Text type="secondary"
                          className=" overflow-ellipsis w-full truncate"
                          // style={{ fontSize: "13px" }}
                        >
                          {renderCreatedAt(jobDescription.created_at)}
                        </Text>
                      </div>
                      <div className="flex flex-col space-x-2 items-center">
                        <Button href={jobDescription.form_url} type="primary">
                          Apply
                        </Button>
                      </div>
                    </div>
                  </div>
                </>

                // </Link>
              ))}

            {totalJobs > 10 && !short_link_id && (
              <div className="w-full flex justify-end my-12 mb-4">
                <Pagination
                  current={currentPage}
                  defaultPageSize={pageSizeRef.current}
                  total={totalJobs}
                  onChange={handlePageChange}
                  showSizeChanger={true}
                />
              </div>
            )}
          </div>
        </>
      )}
 </div>
  );
};

export default JobCard;
