import React, { useEffect, useState } from "react";
import { Typography, Divider, Skeleton } from "antd";
import { getAllJDByOrgName } from "../../services/api"
import parse from "html-react-parser";
import { useParams } from "react-router-dom"
import { Helmet } from "react-helmet"
import Linkdin from "../../assets/Linkdin";
import Twitter from "../../assets/Twitter";
import Facebook from "../../assets/Facebook";
import Instagram from "../../assets/Instagram";
import Youtube from "../../assets/Youtube";
import { OrgInfo } from "../../../types/type";
import { AxiosError } from "axios";
import throwError from "../../services/throwError"
import CareerPage from "./CareerPage";
import PageNotFound from "../pageNotFound/PageNotFound"
const { Text } = Typography;

interface Params extends Record<string, string> {
  company_name: string;
  jd_id: string;
}

// type CompanyInfoprops = {
//   getLogoAndName: (logo: string, name: string) => void;
// };

const CompanyInfo: React.FC = () => {
  //default company information
  const [loading, setLoading] = useState(false);
  const [orgInfo, setOrgInfo] = useState<OrgInfo>({
    active: true,
    data: {
      company_size: "",
      description: "",
      facebook: "",
      insta: "",
      linkedin: "",
      location: "",
      mission_statement: "",
      twitter: "",
      type: true,
      website: "",
      youtube: "",
      logo: "",
    },
    id: "",
    name: "",
    state: "",
    tenantId: "",
  });
  const { company_name } = useParams<Params>()
  const params: any = { company_name };
  const [isOrgExist, setIsOrgExist] = useState<boolean>(true);

  //setting company information by fetching getalljobs of organization api
  const fetchJobs = async (page: number, perPage: number) => {
      setLoading(true);
    getAllJDByOrgName(params.company_name, page, perPage, "").then((response) => {
      setOrgInfo(response.org_info)
      setLoading(false)
    }).catch((reason: AxiosError) => {
      setLoading(false)
      if (reason.response?.status === 404) {
        setIsOrgExist(false)
      } else
        throwError(reason)

    })


  };

  function isUrlValid(url:string) {
    // Check if the URL starts with "https://" or "http://"
    if (!/^https?:\/\//i.test(url)) {
        // If not, prepend "https://"
        url = 'https://' + url;
    }
    return url;
}

  // calling a function when component render first time
  useEffect(() => {
    fetchJobs(1, 9);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isOrgExist) return <PageNotFound />

  // in return displaying card skeleton which contain 6 card while api fetching data
  return (

    <>

      <CareerPage logo={orgInfo.data.logo ? orgInfo.data.logo : ""} title={orgInfo.name}>
        {orgInfo.id.length > 0 &&
      <Helmet>
        <title>{`Careers at ${orgInfo.name}`}</title>
      </Helmet>
    }
    <div className="w-full flex justify-center">
      {loading ? (
        <div className="w-full">
          <Skeleton className="mt-4" active />
        </div>
      ) : (
        <div className="w-full">
          <div className="flex flex-col">
            <Text className="font-semibold text-xl">{orgInfo.name}</Text>
            <Text
              className=" text-gray-500"
              style={{ fontSize: "14px", fontWeight: "400px" }}
            >
              {orgInfo.data.mission_statement}
            </Text>
            <div className="mt-4 flex  justify-start flex-wrap" style={{gap:"30px"}}>
              {orgInfo.data.website && (
                <div className="flex flex-col">
                  <Text
                    className="font-bold text-gray-500"
                    style={{ fontSize: "14px" }}
                  >
                    Website
                  </Text>
                 <a href={isUrlValid(orgInfo?.data?.website)}  rel="noreferrer" target= "_blank" className="text-blue-500 truncate" style={{width:"180px"}}>{orgInfo?.data?.website}</a>
                </div>
              )}
              {orgInfo.data.location && (
                <div className="flex flex-col">
                  <Text
                    className=" font-bold text-gray-500"
                    style={{ fontSize: "14px" }}
                  >
                    Location
                  </Text>
                  <Text
                    className=" text-gray-500"
                    style={{ fontSize: "14px", fontWeight: "400px" }}
                  >
                    {orgInfo.data.location}
                  </Text>
                </div>
              )}
              {orgInfo.data.company_size && (
                <div className="flex flex-col">
                  <Text
                    className="font-bold text-gray-500"
                    style={{ fontSize: "14px" }}
                  >
                    Company size
                  </Text>
                  <Text
                    className=" text-gray-500"
                    style={{ fontSize: "14px", fontWeight: "400px" }}
                  >
                    {orgInfo.data.company_size}
                  </Text>
                </div>
              )}
            </div>
          </div>
          {orgInfo.data.description && <Divider />}
          {orgInfo.data.description && (
            <Text className="text-base font-bold">About us</Text>
          )}
          <br />
          <br />
          {orgInfo.data.description && (
            <Text
              className="mt-2"
              style={{ fontSize: "14px", fontWeight: "400px" }}
            >
              {parse(orgInfo.data.description)}
            </Text>
          )}
          <div className="flex flex-col justify-start mt-4 ">
              {(orgInfo.data.linkedin ||
                orgInfo.data.facebook ||
                orgInfo.data.insta ||
                orgInfo.data.youtube ||
                orgInfo.data.twitter) && (
                <Text className="text-base font-semibold mb-2">
                  Follow {orgInfo.name}
                </Text>
              )}

              <div className="flex justify-start  gap-2">
                {orgInfo.data.linkedin && (
                  <div
                    className=" border rounded w-max h-max p-1"
                    
                  >
                    <a
                      target="_blank"
                      href={isUrlValid(orgInfo.data.linkedin)}
                      rel="noopener noreferrer"
                      className=" "
                    >
                      <Linkdin size={24} />
                    </a>
                  </div>
                )}
                {orgInfo.data.twitter && (
                  <div
                    className=" border rounded w-max h-max p-1"
                   
                  >
                    <a
                      target="_blank"
                      href={isUrlValid(orgInfo.data.twitter)}
                      rel="noopener noreferrer"
                      className=""
                    >
                      <Twitter size={24} />
                    </a>
                  </div>
                )}
                {orgInfo.data.facebook && (
                    <div
                    className=" border rounded w-max h-max p-1"
                   
                  ><a
                    target="_blank"
                    href={isUrlValid(orgInfo.data.facebook)}
                    rel="noopener noreferrer"
                  >
                    <Facebook  size={24}  />
                  </a></div>
                )}
                {orgInfo.data.insta && (
                    <div
                    className=" border rounded w-max h-max p-1"
                   
                  ><a
                    target="_blank"
                    href={isUrlValid(orgInfo.data.insta)}
                    rel="noopener noreferrer"
                  >
                    <Instagram  size={24}  />
                  </a></div>
                )}
                {orgInfo.data.youtube && (
                   <div
                   className=" border rounded w-max h-max p-1"
                  
                 > <a
                    target="_blank"
                    href={isUrlValid(orgInfo.data.youtube)}
                    rel="noopener noreferrer"
                  >
                    <Youtube   size={24} />
                  </a></div>
                )}
              </div>
            </div>
        </div>
      )}
        </div>
      </CareerPage>
    </>
  );
};

export default CompanyInfo;
