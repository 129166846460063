import React from "react"
import CarrierPageBg from "../../assets/CareerPageBg"
import { Link, useParams } from "react-router-dom"
import { Avatar, Divider } from "antd"
import PublicPageFooter from "./PublicPageFooter"
import JobCard from "./JobPreview"

interface Params extends Record<string, string> {
    company_name: string
    jd_id: string
}


const CareerPage = (props: { children: React.ReactNode, title: string, logo: string }) => {
    const { jd_id, company_name } = useParams<Params>()
    const params = { jd_id, company_name }

    const getProfilePlaceholder = (name: string) => {
        if (!name) return ""

        return name.charAt(0).toUpperCase()
    }

    return (
        <>
            <div className="relative" style={{ overflowX: "hidden" }}>
                <CarrierPageBg />
            </div>
            <div className="flex flex-col items-center w-full min-h-screen bg-white mb-28">
                <div
                    className="md:w-7/12 2xl:w-6/12 lg:w-7/12 xl:w-7/12  w-full flex justify-start mt-4"
                    style={{ marginTop: "-46px", zIndex: 1 }}
                >
                    {props.logo ? (
                        <Link to={`/${params.company_name}`}>
                            <img
                                src={props.logo}
                                alt="company-logo"
                                className="h-16  w-16 sm:h-16 sm:w-16 md:h-16 md:w-16 lg:h-20 lg:w-20 xl:h-20 xl:w-20 2xl:h-24 2xl:w-24 rounded ml-2 object-cover"
                                style={{ boxShadow: "2px 2px 4px 0 rgba(0, 0, 0, 0.25)" }}
                            />
                        </Link>
                    ) : (
                            <Link to={`/${params.company_name}`}>
                            <Avatar
                                className="h-16 w-16 sm:h-16 sm:w-16 md:h-16 md:w-16 lg:h-20 lg:w-20 xl:h-20 xl:w-20 2xl:h-24 2xl:w-24 rounded ml-2 object-cover  text-black uppercase"
                                    icon={getProfilePlaceholder(props.title)}
                                style={{
                                    boxShadow: "2px 2px 4px 0 rgba(0, 0, 0, 0.25)",
                                    backgroundColor: "#F0EFFE",
                                    fontSize: "30px",
                                }}
                            />
                        </Link>
                    )}
                </div>
                <>
                    <div className="flex mt-1 md:w-7/12 2xl:w-6/12 lg:w-7/12 xl:w-7/12 sm:w-full mx-2">
                        <div className="w-full p-2">
                            {props.children}
                        </div>
                    </div>
                </>
                <div className="md:w-7/12 2xl:w-6/12 lg:w-7/12 xl:w-7/12  w-full mx-2 ">
                    <div className="w-full flex justify-center">
                        <Divider />
                    </div>
                    <JobCard />
                </div>
            </div>
            <div className="mt-auto">
                <PublicPageFooter />
            </div>
        </>
    )
}

export default CareerPage
