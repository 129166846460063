import React from "react";

const Youtube = (props:{size:number}) => {
  return (
    <svg
    width={props.size?props.size:"35"}
    height={props.size?props.size:"35"}
      viewBox="0 0 50 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M48.8726 5.4808C48.5859 4.42145 48.0267 3.45567 47.2507 2.67964C46.4747 1.90361 45.509 1.3444 44.4497 1.0577C40.5714 0 24.9628 0 24.9628 0C24.9628 0 9.35341 0.032016 5.47517 1.08972C4.41581 1.37644 3.45005 1.93568 2.67405 2.71174C1.89805 3.48781 1.3389 4.45362 1.05227 5.51301C-0.120804 12.4039 -0.575861 22.904 1.08448 29.5192C1.37114 30.5786 1.93031 31.5443 2.7063 32.3204C3.4823 33.0964 4.44805 33.6556 5.50739 33.9423C9.38562 35 24.9946 35 24.9946 35C24.9946 35 40.6034 35 44.4815 33.9423C45.5408 33.6556 46.5066 33.0964 47.2827 32.3204C48.0587 31.5444 48.6179 30.5786 48.9046 29.5192C50.1419 22.6186 50.5231 12.1249 48.8726 5.4808Z"
        fill="#FF0000"
      />
      <path
        d="M19.9943 24.9999L32.9428 17.5L19.9943 10V24.9999Z"
        fill="white"
      />
    </svg>
  );
};

export default Youtube;
