/* eslint-disable react/style-prop-object */

import React from "react";
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter";
import { Typography } from "antd";
const {  Link } = Typography;

const PublicPageFooter = () => {
  return (
    <>
      <div className="flex justify-center border-t mt-2 items-center px-4 text-sm font-medium py-2" style={{ backgroundColor: '#FFF9F7' }}>
        <div className="inline-block space-x-1">
          <span>{capitalizeFirstLetter("Powered by")} <Link target="_blank" href="https://www.tacitbase.com/"  className="font-semibold text-sm" style={{color:"#F26046"}}>Tacitbase</Link></span>
        </div>
      </div>
    </>
  );
};
export default PublicPageFooter;
