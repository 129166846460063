export const formatSalary = (salary: number, currency: string) => {
    if (salary === 0) {
        return "";  // Return empty string if salary is 0
    }

    let formattedSalary;
    let currencySymbol;

    // Set currency symbol based on currency type
    if (currency === "usd") {
        currencySymbol = "$";
    } else if (currency === "inr") {
        currencySymbol = "₹";
    } else {
        currencySymbol = currency; // Fallback to currency code if symbol is not known
    }

    // Format salary based on value
    if (salary >= 1e9) {
        formattedSalary = (salary / 1e9) + "B";
    } else if (salary >= 1e6) {
        formattedSalary = (salary / 1e6).toFixed(0) + "M";
    } else if (salary >= 1e3) {
        formattedSalary = (salary / 1e3).toFixed(0) + "K";
    } else {
        formattedSalary = salary.toString();
    }

    return `${currencySymbol}${formattedSalary}`;
};
