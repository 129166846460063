import React, { useState } from "react"
import PhoneInput from "react-phone-input-2"
import { Input, Upload, Typography } from "antd"

import { UploadOutlined } from "@ant-design/icons"
import TextArea from "antd/lib/input/TextArea"
import { Checkbox, Form, UploadProps } from "antd/lib"
import Link from "antd/lib/typography/Link"
import Paragraph from "antd/lib/typography/Paragraph"

import { ExtendedTableColumn } from "../../../types/type"
import { EEO_STATEMENT1, EEO_STATEMENT2, EMAIL_FORMATE, FORM_SUBMISSION_WARNING_MSG, URL_FORMATE } from "../../constant"
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter"
import FormItem from "../../components/ant/FormItem"
import { LabeledIconButton } from "../../components/ant/Button"
const { Text } = Typography



type FormProps = {
  column: ExtendedTableColumn
  setValue: (value: { [key: string]: string }) => void
  defaultValue: any
  showPreview: boolean
  handleChangeLabel?: (label: string) => void
  handlekeyPress?: (e: any) => void
  handleChangeSublabel?: (e: string) => void
  onClick: () => void
  attachedFile?: (file: any) => void,
  isLoading: boolean
}

const CustomFormField = (props: FormProps) => {
  const phoneInputCSS = {
    height: 32,
    width: "50%",
  }



  const [fileList, setFileList] = useState<any[]>([])
  const removeCode = (phone: string, country: any) => {
    return phone.substring(country.dialCode.length)
  }
  const propsUpload: UploadProps = {
    onRemove: () => {
      setFileList([])
    },
    beforeUpload: (file) => {
      setFileList([ file])
      return false
    },
    fileList,
  }
// Custom validator function with types
const fileSizeValidator = async (_:any,file: any) => {
  const currentFile = file?.file;
  if (!currentFile) {
    return Promise.resolve();
  }
  const isFileSizeValid = currentFile.size / 1024 / 1024 < 10; 
  if (!isFileSizeValid && fileList.length > 0) {
    return Promise.reject(new Error('File must be smaller than 10MB!'));
  }
  return Promise.resolve();
};
  
  const addPhone = (phone: string, country: any) => {
    const phoneNumber: string = removeCode(phone, country)
    props.setValue({ country_code: country.dialCode, phone: phoneNumber })
  }

  switch (props.column.type) {
    case "url":
      return (
        <FormItem
          required
          name={props.column.name}
          label={props.column.label}
          rules={[
            {
              required: props.column.isRequired,
              message: FORM_SUBMISSION_WARNING_MSG,
            },
            { type: "url", message: URL_FORMATE },
          ]}
        >
          <Input
            style={!props.showPreview ? { pointerEvents: "none" } : {}}
            readOnly={!props.showPreview}
          />
        </FormItem>
      )
    case "date":
    case "email":
      return (
        <FormItem
          required
          name="email"
          label={props.column.label}
          rules={[
            {
              required: props.column.isRequired,
              message: FORM_SUBMISSION_WARNING_MSG,
            },
            { type: "email", message: EMAIL_FORMATE },
          ]}
        >
          <Input
            style={!props.showPreview ? { pointerEvents: "none" } : {}}
            readOnly={!props.showPreview}
          />
        </FormItem>
      )
    case "string":
      return (
        <>
          <FormItem
            required
            name={props.column.name}
            label={props.column.label}
            rules={[
              {
                required: props.column.isRequired,
                message: FORM_SUBMISSION_WARNING_MSG,
              },
            ]}
          >
            <Input
              style={!props.showPreview ? { pointerEvents: "none" } : {}}
              name={props.column.name}
              readOnly={!props.showPreview}
            />
          </FormItem>
        </>
      )
    case "number":
      return (
        <>
          <FormItem
            required
            name={props.column.name}
            label={props.column.label}
            rules={[
              {
                required: props.column.isRequired,
                message: FORM_SUBMISSION_WARNING_MSG,
              },
            ]}
          >
            <Input
              type="number"
              style={!props.showPreview ? { pointerEvents: "none" } : {}}
              name={props.column.name}
              readOnly={!props.showPreview}
            />
          </FormItem>
        </>
      )
    case "heading":
      return (
        <div className="leading-none">
          <div
            className={`editable-div ${props.column.size === "default" || props.column.size === "large"
              ? "text-xl"
              : "text-base"
              } ${props.showPreview && "pointer-events-none"
              } leading-none w-full rounded  font-semibold `}
          >
            {props.column.label}
          </div>
          <div
            className={`editable-div ${props.showPreview && "pointer-events-none"
              } text-sm inputCSSOnlyForFocus w-full rounded   mt-1`}
          >
            {props.column.subheading}
          </div>
          <div className="flex mt-2">
            <hr
              className="w-full"
              style={{ borderColor: "#0F0A0D", opacity: "0.3" }}
            ></hr>
          </div>
        </div>
      )
    case "divider":
      return (
        <div className=" py-2">
          <hr
            className="w-full "
            style={{
              borderColor: "#0F0A0D",
              opacity: "0.3",
              borderTop: `${props.column.height}px ${props.column.style}`,
            }}
          ></hr>
        </div>
      )
    case "button":
      return (
        <>
          <LabeledIconButton
            type="primary"
            htmlType="submit"
            label={capitalizeFirstLetter(props.column.label)}
            onClick={props.onClick}
            loading={props.isLoading}
          />
        </>
      )
    case "file":
      return (
        <div className="w-72">
          <FormItem
            required
            label={props.column.label}
            name={"file"}
            rules={[
              {
                required: props.column.isRequired,
                message: `Please upload a file`,
              },
              {
                validator: fileSizeValidator,
              },
            ]}
            style={{ width: "100%" }}
          >
            <Upload
              name={props.column.name}
              // onChange={}
              onChange={(file: any) => props.setValue({ "file": file })}
              {...propsUpload}
              fileList={fileList}
              // beforeUpload={() => false} // Prevents auto-uploading, allows custom handling
              // showUploadList={false} // Hides the default upload list
              accept=".pdf"
              style={{ width: "100%" }}
            >
              <LabeledIconButton
                label="Click to Upload"
                onClick={() => { }}
                htmlType="button"
                icon={<UploadOutlined />}
              ></LabeledIconButton>
            </Upload>
          </FormItem>
        </div>
      )
    case "TermsAndConditions":
      return (
        <>
          <Form.Item
            required
            rules={[
              {
                required: true,
                message: "",
              },
            ]}
            valuePropName="checked"
            name={props.column.label}
            label=""
            style={{ margin: 0 }}
          >
            <Checkbox>
              I agree to{" "}
              <Link href={`${props.column.link}`} target="_blank">
                Terms & conditions.
              </Link>
            </Checkbox>
          </Form.Item>
        </>
      )
    case "phone":
      return (
        <>
          <FormItem
            rules={[
              {
                required: props.column.isRequired,
                message: FORM_SUBMISSION_WARNING_MSG,
              },
            ]}
            name={props.column.name}
            required
            label={props.column.label}
          >
            <PhoneInput
              enableSearch
              countryCodeEditable={false}
              inputStyle={phoneInputCSS}
              disabled={props.showPreview ? false : true}
              country={"us"}
              onChange={addPhone}
              inputClass="phone-input"
            />{" "}
          </FormItem>
        </>
      )
    case "paragraph":
      return (
        <>
          <FormItem
            label={props.column.label}
            rules={[
              {
                required: props.column.isRequired,
                message: FORM_SUBMISSION_WARNING_MSG,
              },
            ]}
            name={props.column.name}
          >
            <TextArea
              disabled={!props.showPreview}
              rows={4}
              name="description"
            />
          </FormItem>
        </>
      )
    case "EEO":
      return (
        <>
          <Text>{EEO_STATEMENT1}</Text>
          <Paragraph>{EEO_STATEMENT2}</Paragraph>
        </>
      )
    default:
      return null
  }
}
export default CustomFormField
