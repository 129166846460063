import throwError from "./throwError";
import { Candidate, getFilterOptions, getFilterOptionsRes, Jobs, uploadAttachmentOfCandidateRes } from "../../types/type"
import axios, { AxiosError, AxiosInstance } from "axios"

let api: AxiosInstance;

export const configureAPI = (baseURL: string | undefined) => {
  api = axios.create({ baseURL: baseURL });
};

const epPublicService = "/v1/public/jobs/"
const epCDB = "/v1/db/";





export const getJD = (jd_id: string, suffix: string) => {
  const ep = epPublicService + `${jd_id}?org_career_page_url_suffix=${suffix}`
  return new Promise<Jobs>((resolve, reject) => {
    api
      .get(ep)
      .then((res) => {
        if (res.status === 200) {
          resolve(res.data.jobs)
        }
      })
      .catch((reason: AxiosError) => {
        // throwError(reason)
        reject(reason)
      })
  })
}

export const getAllJDByOrgName = (suffix: string, page: number, per_page: number,query:string) => {
  let ep = epPublicService + `?org_career_page_url_suffix=${suffix}&page=${page}&per_page=${per_page}`
  ep=query?ep+`&${query}`:ep;
  return new Promise<any>((resolve, reject) => {
    api
      .get(ep)
      .then((res) => {
        if (res.status === 200) {
          resolve(res.data.jobs)
        }
      })
      .catch((reason: AxiosError) => {
        // throwError(reason)
        reject(reason)
      })
    })
}
export const getFormOfJD = (suffix: string, jd_id: string) => {
  const ep = epPublicService + `form/${jd_id}?org_career_page_url_suffix=${suffix}`
  return api.get(ep)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      }
    })
    .catch((error: AxiosError) => {
      throwError(error)
    })
};


export const uploadAttachmentOfCandidate = (
  formData: any,
  modelName: string,
  recordId: string,
  orgId: string,
) => {
  const ep =
    epCDB + `attachments/pub?model_name=${modelName}&record_id=${recordId}&org_id=${orgId}&type=form`;
  return new Promise<uploadAttachmentOfCandidateRes>((resolve, reject) => {
    api
      .post<any>(ep, formData)
      .then((res) => {
        if (res.status === 200) {
          resolve(res.data.attachments);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};


export const createCandidateFromLink = (medium_type:string,req: any) => {
  const ep = epCDB +`candidates/pub?medium_type=${medium_type}`;
  return new Promise<Candidate>((resolve, reject) => {
    api
      .post(ep, req)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          resolve(res.data.candidates);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};

// get departments 
export const getAllDepartmentOfCreatedJobs=(name:string)=>{
  const ep =  `${epPublicService}departments/${name}`
  return new Promise<getFilterOptions>((resolve, reject) => {
    api
      .get<getFilterOptionsRes>(ep)
      .then((res) => {
        if (res.status === 200) {
          resolve(res.data.jd_data)
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason)
        reject(reason)
      })
  })
}