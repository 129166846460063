import React from "react"
import { PAGE_NOT_FOUND, PAGE_NOT_FOUND_TITLE } from "../../constant"
import ProductName from "../product/ProductName"
import BottomBubble from "./BottomBubble"
import TopBubble from "./TopBubble"
import Title from "antd/es/typography/Title"
const PageNotFound = () => {
  document.title = `${PAGE_NOT_FOUND_TITLE}`
  return (
    <>
      {" "}
      <div className="flex  flex-col justify-center items-center h-screen">
        <TopBubble />
        <div className="flex flex-col justify-center items-center space-x-4 ">
          {" "}
          <ProductName />
          <h1 className="text-9xl tracking-widest h-36">404</h1>
          <Title level={2} className="tracking-widest">O-o-oh!</Title>{" "}
          <Title level={3} className="tracking-wider">{PAGE_NOT_FOUND}</Title>
        </div>{" "}
        <BottomBubble />
      </div>{" "}
    </>
  )
}
export default PageNotFound
