import moment from 'moment'

export const renderCreatedAt = (created_at: string): string => {
    const createdAtMoment = moment.utc(created_at) // Parse timestamp as UTC to avoid timezone issues
    const currentTimeMoment = moment.utc() // Get current time in UTC
    const timeDiff = moment.duration(currentTimeMoment.diff(createdAtMoment))

    if (timeDiff.asSeconds() < 60) {
        return "Just now"
    } else if (timeDiff.asMinutes() < 60) {
        return `${Math.floor(timeDiff.asMinutes())} min ago`
    } else if (timeDiff.asHours() < 24) {
        return `${Math.floor(timeDiff.asHours())} hours ago`
    } else if (timeDiff.asDays() < 2) {
        return 'Yesterday'
    } else if (timeDiff.asDays() < 7) {
        return `${Math.floor(timeDiff.asDays())} days ago`
    } else if (timeDiff.asWeeks() < 5) {
        return `${Math.floor(timeDiff.asWeeks())} weeks ago`
    } else if (timeDiff.asMonths() < 12) {
        return `${Math.floor(timeDiff.asMonths())} months ago`
    } else {
        return createdAtMoment.format('MMM DD, YYYY')
    }
}
