import React from "react";

const Twitter =  (props:{size:number}) => {
  return (
    <svg
    width={props.size?props.size:"35"}
    height={props.size?props.size:"35"}
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.382 14.8203L33.1313 0H30.1098L19.04 12.868L10.1981 0H0L13.3705 19.4589L0 35H3.02148L14.712 21.411L24.0494 35H34.2475L20.3812 14.8203H20.382ZM16.2438 19.6301L14.8889 17.6925L4.11004 2.27445H8.75082L17.4491 14.7175L18.8037 16.6551L30.1112 32.8289H25.471L16.2438 19.6309V19.6301Z"
        fill="black"
      />
    </svg>
  );
};

export default Twitter;
