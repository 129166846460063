import React, { useEffect, useState } from "react";
import { Link, useParams,useSearchParams } from "react-router-dom";
import FormHosting from "./FormHosting";
import CareerPageBg from "../../assets/CareerPageBg";
import { Avatar, Result, Form, Typography, Skeleton, Divider } from "antd"
import PublicPageFooter from "../careerpage/PublicPageFooter";
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter";
import { createCandidateFromLink, getFormOfJD, uploadAttachmentOfCandidate } from "../../services/api";
import { ExtendedTableColumn } from "../../../types/type"
import { OrgInfo } from "../../../types/type"
import Title from "antd/es/typography/Title";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Helmet } from "react-helmet"
import { renderCreatedAt } from "../../utils/renderCreatedAt";
import PageNotFound from "../pageNotFound/PageNotFound";

const { Text } = Typography;

export type FormResponse = {
  form: {
    form_id: string;
    title: string;
    form_data: {
      elements: ExtendedTableColumn[];
    };
    creater_id: string;
    company_logo: string;
    form_url: string;
    status: string;
    created_at: string;
    updated_at: string;
    jd_id: string;
    jd_title: string;
    description: string;
    company_name: string;
    jd_status: string;
    jd_creater_id: string;
    jd_public_url: string;
    jd_form_id: string;
    number_of_open_positions: number;
    experience_level_from: number;
    experience_level_to: number;
    salary_currency_type: string;
    salary_range_from: number;
    salary_range_to: number;
    department: string;
    job_type: string;
    salary_type: string;
    city: string;
    state: string;
    country: string;
    org_info: OrgInfo;
    board_id: string;
    orgID: string;
    jd_created_at: string;
    jd_updated_at: string;
    short_link_id: string;
  };
  locations?: Array<{
    city: string;
    state: string;
    country: string;
  }>;
};

const FormPreview = () => {
  const initialFormData: FormResponse = {
    form:{
      form_id: "",
    title: "",
    form_data: {
      elements: [],
    },
    creater_id: "",
    company_logo: "",
    form_url: "",
    status: "",
    created_at: "",
    updated_at: "",
    jd_id: "",
    jd_title: "",
    description: "",
    company_name: "",
    jd_status: "",
    jd_creater_id: "",
    jd_public_url: "",
    jd_form_id: "",
    number_of_open_positions: 0,
    experience_level_from: 0,
    experience_level_to: 0,
    salary_currency_type: "",
    salary_range_from: 0,
    salary_range_to: 0,
    department: "",
    job_type: "",
    salary_type: "",
    city: "",
    state: "",
    country: "",
    board_id: "",
    org_info: {
      active: true,
      data: {
        company_size: "",
        description: "",
        facebook: "",
        insta: "",
        linkedin: "",
        location: "",
        mission_statement: "",
        twitter: "",
        type: true,
        website: "",
        youtube: "",
        logo: "",
      },
      id: "",
      name: "",
      state: "",
      tenantId: "",
    },
    orgID: "",
    jd_created_at: "",
    jd_updated_at: "",
    short_link_id: "",
    },
    locations:[],
    
  };
  interface RouteParams {
    company_name: string;
    form_id: string;
    [key: string]: string; // Allow additional string keys if needed
  }
  const [submittedForm, isSubmittedForm] = useState<boolean>(false);
  const [formField, addFormField] = useState<ExtendedTableColumn[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [JDformData, setFormData] = useState<FormResponse>(initialFormData);
  const { company_name, form_id } = useParams<RouteParams>(); // useParams with RouteParams type
  const [isLoading, setLoadingForApiCalling] = useState<boolean>(false)
  const [isFormExist, setIsFormExist] = useState(true);

  // Now you can create an object with the types inferred
  const params: any = { company_name, form_id };
  useEffect(() => {
    setLoading(true);
    getFormOfJD(params.company_name, params.form_id).then(
      (form: FormResponse) => {
        addFormField(form.form.form_data.elements);
        setFormData(form);
        setLoading(false);
        setIsFormExist(true);
      }
    ).catch(()=>{
      setLoading(false);
      setIsFormExist(false)
    });
  }, []);

  const [form] = Form.useForm();
  const [formValue, setFormValue] = useState<any>({});
  const [searchParams] = useSearchParams();
  const src_id = searchParams.get('tb_src')||"";
  const medium_id = searchParams.get('utm_medium')||"";


  const setValue = (value: any) => {
    if (value["country_code"]) {
      value["country_code"] = parseInt(value["country_code"], 10);
    }
    form.setFieldsValue(value);
    setFormValue({ ...formValue, ...value });
  };

  const submitData = () => {
    form.validateFields().then((value:any) => {
   
      const apiRequestData: any = {
        data: [],
        custom: {},
      };
      formField.forEach((element) => {
        if (element.custom === false) {
          apiRequestData.data.push({
            key: element.name,
            value: formValue[element.name],
          });
        } else if (element.custom === true) {
          apiRequestData.custom[element.name] = formValue[element.name];
        }
      });
      if (formValue["country_code"]) {
        apiRequestData.data.push({
          key: "country_code",
          value: formValue["country_code"],
        });
      }
      const formData = new FormData()
      if (formValue["file"] && formValue["file"].file.status !== "removed") {
        formData.append("file", formValue["file"].file);
      }
      const req = { ...formValue, source: { from: src_id, jd: JDformData.form.jd_id, medium: medium_id }, org_id: JDformData.form.orgID }
      setLoadingForApiCalling(true)
      createCandidateFromLink("pub", req).then((res: any) => {
        if(res.id){
          if (formValue["file"] && formValue["file"].file.status !== "removed" ) {
            uploadAttachmentOfCandidate(formData, "candidates", res.id, JDformData.form.orgID).then((res: any) => {
              if (res) {
                isSubmittedForm(true)
                setLoadingForApiCalling(false)
              }
            }).catch(() => {
              isSubmittedForm(true)
              setLoadingForApiCalling(false)
            })
          } else{
            isSubmittedForm(true)
          }
        }else{
          isSubmittedForm(true)
          setLoadingForApiCalling(false)
        }
      }).catch(() => {
        setLoadingForApiCalling(false)
      })
    }).catch((err: any) => {
      console.log('form value error', err);
    })
  }

  const getProfilePlaceholder = (name: string) => {
    if (!name) return "";

    return name.charAt(0).toUpperCase();
  };

  if (!isFormExist) return <PageNotFound/>
  return (
    <>
      {JDformData.form.jd_id.length > 0 &&
        <Helmet>
          <title>{`${JDformData.form.jd_title} | ${JDformData.form.company_name}`}</title>
          <meta name="description" content="Helmet application" />

        </Helmet>
      }
    <div
      className="flex flex-col  min-h-screen"
      style={{ background: "rgba(0, 0, 0, 0.02)" }}
    >
      {submittedForm ? (
        <div className="animate-popover_fadeIn mt-12 flex justify-center items-center flex-grow">
          <div className="w-full md:w-7/12 2xl:w-6/12 lg:w-7/12 xl:w-7/12 mx-2">
            <div className="w-full flex-col  bg-white border border-tacitbase-tertiary1 border-opacity-10 my-2 pt-4 rounded text-base font-medium ">
              <Result status="success">
                <Title className="text-center w-full" level={4}>
                  {" "}
                  Application submitted!
                </Title>

                <div className="flex flex-col  justify-center items-center space-y-3  ">
                  <div>
                    <Text
                      className="  w-full  text-opacity-50 text-black"
                      // style={{ fontSize: "13px" }}
                    >
                      You have successfully applied to the{" "}
                      <span className="font-bold text-black text-opacity-70">
                        {JDformData?.form.jd_title}
                      </span>{" "}
                      position.
                    </Text>
                  </div>
                  <div>
                    <Link
                      className="font-medium text-base flex space-x-2 items-center  "
                      to={`/${params.company_name}`}
                      style={{ color: "#1677ff" }}
                    >
                      <span>
                        <ArrowLeftOutlined />
                      </span>
                      <span className="font-medium text-sm">
                        {capitalizeFirstLetter("Go to jobs")}
                      </span>
                    </Link>
                  </div>
                </div>
              </Result>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-col items-center  w-full flex-grow bg-white">
          <div className="relative w-full">
            <CareerPageBg />
          </div>
          <div
            className="flex justify-start mt-4 md:w-7/12 2xl:w-6/12 lg:w-7/12 xl:w-7/12  w-full"
            style={{ marginTop: "-46px", zIndex: 1 }}
          >
            {JDformData?.form.org_info.data.logo ? (
              <img
                src={JDformData?.form.org_info.data.logo}
                alt="company-logo"
                className="h-16 w-16 sm:h-16 sm:w-16 md:h-16 md:w-16 lg:h-20 lg:w-20 xl:h-20 xl:w-20 2xl:h-24 2xl:w-24 rounded ml-2 object-cover"
                style={{ boxShadow: "2px 2px 4px 0 rgba(0, 0, 0, 0.25)" }}
              />
            ) : (
              <Avatar
                className="h-16 w-16 sm:h-16 sm:w-16 md:h-16 md:w-16 lg:h-20 lg:w-20 xl:h-20 xl:w-20 2xl:h-24 2xl:w-24 rounded ml-2 object-cover  text-black uppercase"
                icon={getProfilePlaceholder(
                  JDformData?.form.org_info?.name ? JDformData?.form.org_info?.name : ""
                )}
                style={{
                  boxShadow: "2px 2px 4px 0 rgba(0, 0, 0, 0.25)",
                  backgroundColor: "#F0EFFE",
                  fontSize: "30px",
                }}
              />
            )}
          </div>

          <div className="flex flex-col items-center justify-center relative md:w-7/12 2xl:w-6/12 lg:w-7/12 xl:w-7/12  w-full bg-white mb-14">
            <div className="w-full">
              <div className=" mx-2">
                <div className="flex flex-col sm:flex-row justify-between">
                {loading ? (
                  <Skeleton className="mt-2" active paragraph={{ rows: 1 }} />
                ) : (
                  JDformData?.form.jd_title && (
                    <div className="flex items-start justify-start gap-1 mt-2">
                      <p
                        className="font-bold text-black"
                        style={{ fontSize: "24px" }}
                      >
                        {capitalizeFirstLetter(JDformData?.form.jd_title)}
                      </p>
                      <Link
                        className="flex justify-center align-middle items-center text-blue-500 mt-2"
                            to={`/${params.company_name}/${JDformData.form.short_link_id}`}
                      >
                        (View job)
                      </Link>
                    </div>
                  )
                )}
                {
                  JDformData.form.jd_created_at && (
                    <div className="flex items-start justify-start gap-1 mt-2">
                    <Text type="secondary"
                          >
                        (Posted {renderCreatedAt(JDformData.form.jd_created_at)})
                          </Text>
                      </div>
                        )
                      }
                </div>
                <div className="mt-2 flex justify-between items-center">
                  <div className="flex flex-col sm:flex-row gap-4 justify-center">
                    {loading ? (
                      <Skeleton
                        className="mx-2"
                        active
                        paragraph={{ rows: 1 }}
                      />
                    ) : (
                      JDformData.form.org_info.name && (
                        <p
                          className="font-bold text-gray-500"
                          style={{ fontSize: "14px",minWidth:"100px"  }}
                        >
                          {JDformData?.form.org_info?.name}
                        </p>
                      )
                    )}
                      {loading ? (
                        <Skeleton
                          className="mx-2"
                          active
                          paragraph={{ rows: 1 }}
                        />
                      ) : JDformData.locations &&
                      JDformData.locations.length > 0 ? (
                        <div className="flex flex-wrap">
                        {JDformData.locations.map((loc, index) => {
                        const locationParts = [loc.city, loc.state, loc.country].filter(Boolean).join(', ');
                        return (
                          <div key={index}>
                            <Text type="secondary">
                               {locationParts}
                              {index !== (JDformData.locations && JDformData?.locations.length - 1) && (
                              <Divider style={{ marginInline: "6px",borderColor: 'rgba(5, 5, 5, 0.3)' }} type="vertical" />
                                  )}
                            </Text>
                          </div>
                            )})}
                        
                      </div>
                      ) : null}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex w-full mx-2 flex-col pb-8 space-y-2 mb-3">
              <div className="h3 break-words mt-14">
                {loading ? (
                  <Skeleton className="mx-2" active paragraph={{ rows: 4 }} />
                ) : (
                  <Form
                    layout="vertical"
                    initialValues={formValue}
                    form={form}
                    onValuesChange={setValue}
                    className="flex mx-2 justify-start"
                  >
                    <FormHosting
                      componyLogo={JDformData.form.company_logo}
                      setValue={setValue}
                      onSubmit={submitData}
                            formData={formField}
                            isLoading={isLoading}
                    />
                  </Form>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      <PublicPageFooter />
      </div>
    </>
  );
};

export default FormPreview;
